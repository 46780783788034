import menuIcon from '@/assets/images/menu.png';
import { useLink } from '@/components/LinkToMenu/useLink';
import BrandSkeleton from '@/components/Skeletons/BrandSkeleton';
import { useBrand } from '@/hooks/useBrand';
import useValidateQueryParams from '@/hooks/useValidateQueryParams';
import { isValidLogoUrl } from '@/utils/isValidLogoUrl';
import clsx from 'clsx';
import styles from './BrandPresentation.module.scss';

const BrandPresentation = () => {
  const { data, isError, isPending } = useBrand();
  const { queryParams } = useValidateQueryParams();
  const { tableNumber, counterNumber } = queryParams();
  const tableLabel = tableNumber && tableNumber.replace('T', '');
  const { link, isLoading } = useLink();

  if (isPending) return <BrandSkeleton />;
  if (isError) return null;

  return (
    <div className={styles.root}>
      <div className={styles.imageWrapper}>
        {data.imageUris && <img src={data.imageUris['720_720']} alt={data.name} />}

        <div className={clsx(styles.header)}>
          <a href={link} data-disabled={isLoading}>
            <div className={styles.menuWrapper}>
              <img src={menuIcon} alt="Menu" />
              View menu
            </div>
          </a>
          <div className={styles.brandAndTableWrapper}>
            <div className={styles.brandLogoWrapper}>
              {isValidLogoUrl(data.logoUris['200_200']) && (
                <img src={data.logoUris['200_200']} alt={`${data.name} Logo`} />
              )}
            </div>

            <div className={styles.tableInfo}>
              {tableNumber && (
                <>
                  <div className={styles.tableLabel}>Table</div>
                  <div className={styles.tableNumber}>{tableLabel}</div>
                </>
              )}
              {counterNumber && <div className={styles.tableNumber}>{counterNumber}</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandPresentation;
