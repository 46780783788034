import styles from '@/screens/Entry/Entry.module.scss';
import { useLink } from './useLink';

export const LinkToMenu = () => {
  const { link, isLoading } = useLink();
  return (
    <a href={link} data-disabled={isLoading} className={styles.white}>
      View menu
    </a>
  );
};
