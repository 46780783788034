import { VITE_INITIAL_CDN_PATH } from '@/constants/constants';
import { useKitchenLink } from '@/hooks/useKitchenLink';
import useValidateQueryParams from '@/hooks/useValidateQueryParams';
import { useOutletKitchen } from './useOutletKitchen';

export const useLink = () => {
  const { queryParams } = useValidateQueryParams();
  const { outletId, brandId } = queryParams();
  const { data: outlet, isLoading: kitchenIsLoading } = useOutletKitchen(outletId);
  const { data: link, isLoading: kitchenLinkIsLoading } = useKitchenLink({
    brandId: brandId ?? '',
    kitchenId: outlet?.data?.kitchenId ?? '',
  });
  const pdfUrl = `${VITE_INITIAL_CDN_PATH}/menus/${brandId}.pdf`;

  return {
    link: link?.data?.menuUrl || pdfUrl,
    isLoading: kitchenIsLoading || kitchenLinkIsLoading,
  };
};
