import { API_DINE_IN_URL } from '@/constants/config';
import { LONG_STALE_TIME } from '@/constants/queries';
import { queryKeys } from '@/constants/queryKeys';
import { fetch } from '@/utils/fetch';
import { useQuery } from '@tanstack/react-query';

export const useOutletKitchen = (outletId: string | null) => {
  return useQuery({
    queryKey: queryKeys.outletKitchen({ outletId }),
    queryFn: ({ signal }) => fetch.get(`${API_DINE_IN_URL}/outlets/${outletId}`, { signal }),
    staleTime: LONG_STALE_TIME,
    enabled: !!outletId,
  });
};
