import { Nullable } from '@/interfaces/Nullable';

export const queryKeys = {
  bill: (params: { outletId: Nullable<string>; tableNumber?: string }) => ['bill', params],
  outletKitchen: (params: { outletId: Nullable<string> }) => ['outletKitchen', params],
  kitchenLink: (params: { brandId: string; kitchenId: string }) => ['kitchenLink', params],
  brand: (params: { brandId: Nullable<string> }) => ['brand', params],
  paymentStatus: (params: { guestId: string; paymentId: Nullable<string> }) => [
    'paymentStatus',
    params,
  ],
  intentStatus: (params: { intentId: string }) => ['intentStatus', params],
  paymentProviders: (params: { pos: string }) => ['paymentProviders', params],
  postPayment: 'postPayment',
  postGooglePay: 'postGooglePay',
  postApplePay: 'postApplePay',
  validateApplePayMerchant: 'validateApplePayMerchant',
};
