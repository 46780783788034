import { API_MM_URL } from '@/constants/config';
import { LONG_STALE_TIME } from '@/constants/queries';
import { queryKeys } from '@/constants/queryKeys';
import { fetch } from '@/utils/fetch';
import { useQuery } from '@tanstack/react-query';

interface GetKitchenLinkParams {
  brandId: string;
  kitchenId: string;
}

interface KitchenLinkResponse {
  menuUrl: string;
}

export const useKitchenLink = ({ brandId, kitchenId }: GetKitchenLinkParams) => {
  return useQuery({
    queryKey: queryKeys.kitchenLink({ brandId, kitchenId }),
    queryFn: ({ signal }) =>
      fetch.get<KitchenLinkResponse>(`${API_MM_URL}/brands/${brandId}/kitchens/${kitchenId}`, {
        signal,
      }),
    staleTime: LONG_STALE_TIME,
    enabled: !!brandId && !!kitchenId,
  });
};
